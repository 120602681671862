@font-face {
  font-family: tnr;
  src: url(./static/TNR.ttf);
}

body {
  margin: 0;
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  /* font-family: tnr; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  font-size: 16px !important;
  @media (max-width: 650px) {
    font-size: 14px !important;
  }
}

* {
  color: white !important;
}

button {
  background: black;
  font-size: 16px;
  letter-spacing: -0.003em;
  line-height: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 24px;
  margin: 0 0 12px 0;
  @media (max-width: 650px) {
    width: 16px;
    margin: 0 0 8px 0;
  }
}

.App {
  text-align: center;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
